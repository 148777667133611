<template>
  <div>
    <fornecedor-varejo-list
      v-if="indModoVarejo"/>
    <fornecedor-industria-list
      v-else/>
  </div>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex';

import FornecedorIndustriaList from '@/spa/fornecedor/industria/FornecedorIndustriaList';
import FornecedorVarejoList from './varejo/FornecedorVarejoList';

export default {
  name: 'FornecedorList',
  components: {
    FornecedorVarejoList,
    FornecedorIndustriaList,
  },
  computed: {
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
  },
};
</script>
